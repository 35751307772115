import Vue from 'vue';

const state = Vue.observable({
        user_email: '',
        user_name: 'user',
});

export const mutations = {
  setUserName(val) {
    state.user_name = val;
  },
  setUserEmail(val){
    state.user_email = val
  }
}

export default state;
