<template>
    <div class="test-page page1">
        <div class="flex max-w-full overflow-x-auto gap-4 lg:gap-6 card-panel scroll-bar">
            <div class="flex items-center justify-center py-24 bg-base-100 rounded-xl w-full" v-if="caseCountLoader">
                <Loader />
            </div>
            <template v-else>
                <card
                    @select="selectCard(card)"
                    v-for="card in caseStatusCard"
                    :key="card.title"
                    :title="card.title"
                    :value="card.value"
                    :color="card.color"
                    :class="{
                        'card--selected': selectedCard && selectedCard.title === card.title,
                    }"
                />
            </template>
        </div>
        <div class="wrapper p-4 content">
            <!-- // loader starts here  -->
            <div class="wrapper wrapper--row justify-between" v-if="cardData">
                <chart class="chart" :name="cardData.chart.name" :data="cardData.chart.data" />
                <test-table class="table" :title="caseEventDetails.name" :subtitle="cardData.table.subtitle" :data="caseEventDetails.data" colorHeader="#0D69D5" @select="handleRowClick" />
            </div>

            <div v-else class="empty-data text-lg flex bg-base-100 justify-center items-center py-32 rounded-xl text-center">
                <Loader />
            </div>

            <div>
                <tabs @select="selectTab" :selected="fakeData.tabs.selected" :tabs="fakeData.tabs.list">
                    <template>
                        <div class="dropdown-menu">
                            <!-- <span>Customize Table</span>
              <svg
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.93934 6.93934L5.87868 8L8 10.1213L9.06066 9.06066L6.93934 6.93934ZM15.0607 3.06066C15.6464 2.47487 15.6464 1.52513 15.0607 0.93934C14.4749 0.353553 13.5251 0.353553 12.9393 0.93934L15.0607 3.06066ZM9.06066 9.06066L15.0607 3.06066L12.9393 0.93934L6.93934 6.93934L9.06066 9.06066Z"
                  fill="white"
                />
                <path
                  d="M6.93934 9.06066L8 10.1213L10.1213 8L9.06066 6.93934L6.93934 9.06066ZM3.06066 0.939339C2.47487 0.353553 1.52513 0.353553 0.939339 0.93934C0.353553 1.52513 0.353552 2.47487 0.939339 3.06066L3.06066 0.939339ZM9.06066 6.93934L3.06066 0.939339L0.939339 3.06066L6.93934 9.06066L9.06066 6.93934Z"
                  fill="white"
                />
              </svg> -->
                        </div>
                    </template>
                    <template #content-1>
                        <test-table-large
                            :key="fakeData.tabs.selected + candidatesTotalPage.total"
                            :handleCaseClick="handleCaseClick"
                            :totalRows="candidatesTotalPage.total"
                            :isLoading="isLoading"
                            :columns="cardData.tableLarge.candidateView.columns"
                            :rows="cardData.tableLarge.candidateView.rows"
                            :filterOptions="columnFilterOptions.candidate"
                            :filterOptionsAsync="columnFilterOptionsAsync.candidate"
                            @handleStatusClick="handleStatusClick"
                            :pagination-options="getPaginationOptions"
                            :filters="columnFilters.candidate"
                            @page-change="onPageChange($event, 'candidate')"
                            @per-page-change="onPageChange($event, 'candidate', true)"
                            @column-filter="onColumnFilters($event, 'candidate')"
                            @search-filter="getSearchOptionsTick($event, 'candidate')"
                            @filters-clear="clearFilters('candidate')"
                            :config="{
                                'select-options': {
                                    enabled: true,
                                    selectOnCheckboxOnly: true,
                                    selectionText: 'case(s) selected',
                                },
                            }"
                            @on-work-allocation="onWorkAllocation($event)"
                            @on-case-delete="onCaseDelete($event)"
                            @on-allocation="onAllocation($event)"
                        >
                        <template #link="{ customData }">
                                <!-- {{ item.customData }} -->
                                <!-- {{case_data.case_reference_id}} -->
                                <span class="cursor-pointer text-primary hover:text-primary-focus" v-text="customData.case_id" @click="handleAutomatedCase(customData)" />
                            </template>
                        </test-table-large>
                    </template>
                    <template #content-2>
                        <test-table-large
                            :key="fakeData.tabs.selected + checksTotalPages.total"
                            :handleCaseClick="handleCaseClick"
                            :isLoading="isLoadingChecks"
                            :totalRows="checksTotalPages.total"
                            :columns="cardData.tableLarge.checkView.columns"
                            :rows="filteredData.checksList"
                            :filterOptions="columnFilterOptions.check"
                            :filterOptionsAsync="columnFilterOptionsAsync.check"
                            :pagination-options="getPaginationOptions"
                            :filters="columnFilters.check"
                            @page-change="onPageChange($event, 'check')"
                            @per-page-change="onPageChange($event, 'check', true)"
                            @column-filter="onColumnFilters($event, 'check')"
                            @search-filter="getSearchOptionsTick($event, 'check')"
                            @filters-clear="clearFilters('check')"
                        >
                            <template #link="{ customData }">
                                <!-- {{ item.customData }} -->
                                <!-- {{case_data.case_reference_id}} -->
                                <span class="cursor-pointer text-primary hover:text-primary-focus" v-text="customData.case_id" @click="handleCheckClicked(customData)" />
                            </template>
                        </test-table-large>
                    </template>
                    <template #content-3>
                        <test-table-large
                            :key="fakeData.tabs.selected + tasksTotalPages.total"
                            :handleCaseClick="handleCaseClick"
                            :isLoading="loadingStates.taskView"
                            :totalRows="tasksTotalPages.total"
                            :columns="cardData.tableLarge.taskView.columns"
                            :rows="filteredData.taskViewList"
                            :filterOptions="columnFilterOptions.task"
                            :filterOptionsAsync="columnFilterOptionsAsync.task"
                            :pagination-options="getPaginationOptions"
                            :filters="columnFilters.task"
                            @page-change="onPageChange($event, 'task')"
                            @per-page-change="onPageChange($event, 'task', true)"
                            @column-filter="onColumnFilters($event, 'task')"
                            @filters-clear="clearFilters('task')"
                            @search-filter="getSearchOptionsTick($event, 'task')"
                        >
                            <template #link="customData">
                                <router-link
                                    :to="{
                                        name: 'Profile Container',
                                        params: { caseid: customData.customData.visible_case_id },
                                        query: { caseid: customData.customData.case_id, action: 'history' },
                                        hash: `#${customData.customData.object_id}`,
                                    }"
                                    class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-300"
                                    >{{ customData.customData.visible_case_id }}</router-link
                                >
                            </template>
                            <template #rich_text="customData">
                                {{ checkData(customData) }}
                            </template>
                        </test-table-large>
                    </template>
                </tabs>
            </div>
            <sidelegend v-model="isOpened" v-if="cardData && cardData.sideLegend" :list="cardData.sideLegend" />
        </div>

        <ModalContent ref="status-modal" name="status-modal" :title="statusModalTitle" submitButtonText="Close" @close="handleCloseStatusModal" :showFooter="false" :width="'90%'">
            <template #content>
                <div class="table-overflow overflow-x-auto mb-4 bg-gray-200 rounded-2xl">
                    <table class="w-full">
                        <thead>
                            <th class="py-3 border-b border-solid border-gray-400 text-base font-medium" :class="[i === 0 ? '' : 'border-l']" v-for="(col, i) in statusModalTable.columns" :key="col.id">
                                {{ col.name }}
                            </th>
                        </thead>
                        <tbody class="">
                            <tr class="">
                                <td class="py-3 text-center border-solid border-gray-400 text-gray-600" :class="[index === 0 ? '' : 'border-l']" v-for="(row, i, index) in statusModalTable.row" :key="i">
                                    {{ row }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Timeline :data="timelineData" />
                <template v-if="checksTimeline && checksTimeline.length">
                    <div class="" v-for="timeline in checksTimeline" :key="`${timeline.header.label}__`">
                        <Timeline :data="timeline" type="checks" class="bg-white rounded-xl" :loading="isTimelineLoading" />
                    </div>
                </template>
                <!-- <div
          class="
            alert
            flex
            justify-center
            max-w-lg
            mx-auto
            alert-info
            text-center
            my-8
          "
          v-else
        >
          No Reference check data
        </div> -->
            </template>
        </ModalContent>
        <WorkAllocation :loadingStates="loadingStates" :allTeams="teamsStates" :allUsers="usersStates" @onWorkAllocateChange="onWorkAllocateChange($event)" @on-save="onWorkAllocationSave($event)" @getCandidateViews="getCandidateViews" @on-reset="onAllocationReset($event)" />
        <AllocationList :isLoading="loadingStates.getAllocation" :allocatedResources="allocatedResources" @removeAllocation="removeAllocation($event)" />
    </div>
</template>

<script>
import card from "@shared/dashboard-components/card";
import chart from "@/components/dashboard-components/chart";
import table from "@shared/dashboard-components/table";
import tableLarge from "@shared/dashboard-components/table-large";
import sidelegend from "@shared/dashboard-components/sidelegend";
import tabs from "@/components/dashboard-components/tabs";
import axios from "@/axios";
import ModalContent from "@/components/modal-content";
import Timeline from "@shared/ChecksTimeline";
import { mapGetters, mapMutations } from "vuex";
import { DateTime } from "luxon";
import Loader from "@/components/loader";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
import { uniqBy } from "lodash";
const WorkAllocation = () => import("./components/work-allocation.vue");
const AllocationList = () => import("./components/allocation-list.vue");

export default {
    name: "case-dashboard",
    components: {
        card,
        chart,
        "test-table": table,
        "test-table-large": tableLarge,
        sidelegend,
        tabs,
        ModalContent,
        Timeline,
        Loader,
        WorkAllocation,
        AllocationList,
    },
    title: "Case Dashboard",
    data() {
        return {
            selectedCard: null,
            isOpened: false,
            fakeData: {
                cards: [],
                tabs: {
                    selected: 1,
                    list: [
                        {
                            id: 1,
                            label: "Subject View",
                            key: "candidate",
                        },
                        {
                            id: 2,
                            label: "Check View",
                            key: "check",
                        },
                        {
                            id: 3,
                            label: "Task View",
                            key: "task",
                        },
                    ],
                },
            },
            timelineData: {
                header: {
                    label: "Subject Form Status",
                    icon: "#",
                },
                timeline: [],
            },
            pagination: {
                candidateView: {
                    page: 1,
                    perPage: 10,
                    total_page: 1,
                },
            },
            paginationOptions: {
                enabled: true,
                mode: "remote",
                dropdownAllowAll: false,
            },
            candidateViewList: [],
            candidateViewParams: {},
            candidatesTotalPage: 0,
            checkViewList: [],
            checksTotalPages: [],
            tasksTotalPages: [],
            isLoading: false,
            serverParams: {
                check: {
                    page: 1,
                    perPage: 10,
                },
                candidate: {
                    page: 1,
                    perPage: 10,
                },
                task: {
                    page: 1,
                    perPage: 10,
                },
            },
            menuOptions: [],
            columnFilters: {
                candidate: null,
                check: null,
                task: null,
            },
            caseCount: [],
            caseCountLoader: false,
            caseStatusCard: [],
            caseEventDetails: {},
            statusModalTitle: "",
            statusModalTable: {
                columns: [
                    { id: 0, name: "First Name" },
                    { id: 1, name: "Surname" },
                    { id: 2, name: "Phone No" },
                    { id: 3, name: "Email Id" },
                    // {id: 4, name: 'DOB'},
                    // {id: 5, name: 'NI No'},
                    { id: 6, name: "Days So Far" },
                    { id: 7, name: "Days Remaining" },
                ],
                row: {
                    candidate_first_name: "",
                    candidate_last_name: "",
                    candidate_phone: "",
                    candidate_email: "",
                    days_so_far: "",
                    days_remaining: "",
                },
            },
            searchLoading: {
                candidate: {},
                check: {},
                task: {},
            },
            searchTimeout: null,
            columnFilterOptionsAsync: {
                candidate: null,
                check: null,
                task: null,
            },
            checksTimeline: [],
            allStatusOptions: [],
            allTeams: [],
            allUsers: [],
            loadingStates: {
                teams: false,
                users: false,
                getAllocation: false,
                taskView: false,
            },
            allocatedResources: [],
            allFormStatusOptions: [],
            teamsStates: [],
            usersStates: [],
            taskViewList: [],
        };
    },
    created() {
        // TODO change to selection of the first card from API:
        // this.selectCard(this.fakeData.cards[0]);
    },
    async mounted() {
        await this.getCaseStatusCount();
        // await this.getCheckLists();
        await this.getCaseStatus();

        if (this.caseStatusCard.length) this.selectCard(this.caseStatusCard[0]);
        await this.getCandidateViews();

        await this.fetchAllStatusOptions();
        await this.fetchAllUsers();
        await this.fetchAllTeams();
        await this.fetchAllFormStatus();
    },
    computed: {
        ...mapGetters(["getTenantId"]),
        cardData() {
            if (!this.selectedCard) return null;
            return {
                chart: {
                    name: "Some data",
                    data: this.caseCount.map((el) => ({
                        name: el.status_name,
                        value: el.cases_count,
                        color: el.status_color,
                    })),
                },
                table: {
                    title: this.selectedCard.title,
                    subtitle: "Entity information",
                    data: [],
                },
                tableLarge: {
                    candidateView: {
                        columns: [
                            {
                                label: "Case ID",
                                field: "case_id",
                                query_key: "case_id",
                                thClass: "nowrap",
                                tdClass: "text-center",
                                config: {
                                    type: "link",
                                },
                            },
                            {
                                label: "Client",
                                field: "client_name",
                                query_key: "client",
                                tdClass: "text-center capitalize",
                            },
                            {
                                label: "Requestor",
                                field: "created_by_name",
                                query_key: "created_by",
                            },
                            {
                                label: "Invitation Date",
                                field: "created_at",
                                query_key: "from_created_at",
                            },
                            {
                                label: "Case Deadline",
                                field: "due_date",
                                query_key: "from_due_date",
                            },
                            {
                                label: "Allocated Group",
                                field: "teams",
                                query_key: "teams",
                                config: {
                                    type: "team_allocation",
                                    units: "groups",
                                    // filterOptions: {
                                    //   enabled: false, // enable filter for this column
                                    // },
                                },
                                prefetchedOptions: this.allTeams.map((team) => ({ ...team, name: team.title })),
                            },
                            {
                                label: "Allocated Users",
                                field: "users",
                                query_key: "users",
                                config: {
                                    type: "user_allocation",
                                    units: "users",
                                    // filterOptions: {
                                    //   enabled: false, // enable filter for this column
                                    // },
                                },
                                prefetchedOptions: this.allUsers.map((user) => ({ ...user, name: user.user_name })),
                            },
                            {
                                label: "Subject form Status",
                                field: "form_status",
                                prefetchedOptions: this.allFormStatusOptions,
                            },
                            {
                                label: "First Name",
                                field: "candidate_first_name",
                                query_key: "first_name",
                            },
                            {
                                label: "Surname",
                                field: "candidate_last_name",
                                query_key: "last_name",
                            },
                            {
                                label: "Package",
                                field: "screening_package_name",
                                query_key: "package",
                                tdClass: "text-center uppercase",
                            },
                            {
                                label: "Education Check",
                                field: "education_references",
                                config: {
                                    type: "indicators",
                                    // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
                                    filterOptions: {
                                        enabled: false, // enable filter for this column
                                    },
                                },
                            },
                            {
                                label: "Employment Check",
                                field: "employment_references",
                                config: {
                                    type: "indicators",
                                    // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
                                },
                                filterOptions: {
                                    enabled: false, // enable filter for this column
                                },
                            },
                            {
                                label: "Checks",
                                field: "other_references",
                                filterOptions: {
                                    enabled: false, // enable filter for this column
                                },
                                config: {
                                    type: "indicators",
                                    // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
                                },
                            },
                            {
                                label: "Case Status",
                                field: "case_status",
                                query_key: "case_status",
                                tdClass: "vgt-center-align",
                                config: {
                                    type: "status",
                                },
                                prefetchedOptions: this.allStatusOptions,
                                // allFormStatusOptions
                            },
                            //   column.filterOptions.filterDropdownItems
                            {
                                label: "Phone",
                                field: "candidate_phone",
                            },
                            {
                                label: "Email",
                                field: "candidate_email",
                            },
                        ]
                            // add isLoading prop for columns:
                            .map((el) => ({
                                ...el,
                                config: {
                                    ...el.config,
                                    isLoading: !!this.searchLoading.candidate[el.field],
                                },
                            })),
                        // rows: this.candidateViewList,
                        rows: this.filteredData.candidateList || [],
                    },
                    checkView: {
                        columns: [
                            {
                                label: "Case ID",
                                field: "case_id",
                                thClass: "nowrap",
                                query_key: "case_id",
                                config: {
                                    type: "link",
                                },
                                filterOptions: {
                                    styleClass: "class1", // class to be added to the parent th element
                                    enabled: true, // enable filter for this column
                                },
                            },
                            {
                                label: "Client",
                                field: "client_name",
                                query_key: "client",
                            },
                            {
                                label: "Requestor",
                                field: "created_by_name",
                                query_key: "created_by",
                            },
                            {
                                label: "Invitation Date",
                                field: "created_at",
                                query_key: "from_created_at",
                            },
                            {
                                label: "Case Deadline",
                                field: "due_date",
                                query_key: "from_due_date",
                            },
                            {
                                label: "Package",
                                field: "screening_package_name",
                                query_key: "package",
                            },
                            {
                                label: "Check",
                                field: "check_title",
                                query_key: "check",
                            },
                            {
                                label: "Alert",
                                field: "alert",
                            },
                            {
                                label: "First Name",
                                field: "candidate_first_name",
                                query_key: "first_name",
                            },
                            {
                                label: "Surname",
                                field: "candidate_last_name",
                                query_key: "last_name",
                            },
                            // {
                            // 	label: 'Education',
                            // 	field: 'education',
                            // 	tdClass: 'vgt-center-align',
                            // 	config: {
                            // 		type: 'indicators',
                            // 		icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
                            // 	},
                            // },
                            // {
                            // 	label: 'Status',
                            // 	field: 'status',
                            // 	tdClass: 'vgt-center-align',
                            // 	config: {
                            // 		type: 'status',
                            // 	},
                            // },
                            {
                                label: "Referee Email ID",
                                field: "referee_email",
                            },
                            {
                                label: "Referee Phone",
                                field: "referee_phone",
                            },
                        ]
                            // add isLoading prop for columns:
                            .map((el) => ({
                                ...el,
                                config: {
                                    ...el.config,
                                    isLoading: !!this.searchLoading.check[el.field],
                                },
                            })),
                        rows: this.filteredData.checkViewList || [],
                    },
                    taskView: {
                        columns: [
                            {
                                label: "Case ID",
                                field: "visible_case_id",
                                config: {
                                    filter: true,
                                    type: "link",
                                },
                            },
                            {
                                label: "Subject First Name",
                                field: "candidate_first_name.label",
                                query_key: "candidate_first_name",
                                config: {
                                    filter: true,
                                },
                            },
                            {
                                label: "Subject Last Name",
                                field: "candidate_last_name.label",
                                query_key: "candidate_last_name",
                                config: {
                                    filter: true,
                                },
                            },
                            {
                                label: "Activity Type",
                                field: "activity_type.label",
                                query_key: "activity_type",
                                config: {
                                    filter: true,
                                },
                            },
                            {
                                label: "Status",
                                field: "status.label",
                                query_key: "status",
                                config: {
                                    filter: true,
                                },
                            },
                            {
                                label: "Due Date",
                                field: "due_date.label",
                                query_key: "due_date.label",
                                config: {
                                    filter: false,
                                },
                            },
                            {
                                label: "Assignee Role",
                                field: "assignee_role.label",
                                query_key: "assignee_role",
                                config: {
                                    filter: true,
                                },
                            },
                            {
                                label: "Assignee Name",
                                field: "assignee",
                                query_key: "assignee",
                                config: {
                                    filter: true,
                                },
                            },
                            {
                                label: "Check",
                                field: "check",
                                query_key: "check",
                                config: {
                                    filter: true,
                                },
                            },
                            {
                                label: "Title",
                                field: "title.label",
                                query_key: "title",
                                config: {
                                    filter: false,
                                    type: "rich_text",
                                },
                                tdClass: "truncate max-w-sm",
                            },
                        ].map((el) => ({
                            ...el,
                            config: {
                                ...el.config,
                                isLoading: !!this.searchLoading.task[el.field],
                            },
                        })),
                        rows: this.filteredData.taskViewList || [],
                    },
                },
                sideLegend: [
                    { label: "No Data", color: "gray" },
                    { label: "In Progress", color: "blue" },
                    { label: "Action Needed", color: "yellow" },
                    { label: "On Hold", color: "orange" },
                    { label: "Post SLA", color: "pink" },
                    { label: "Complete", color: "green" },
                    { label: "Flagged", color: "red" },
                ],
                checksTimeline: [],
            };
        },
        filteredData() {
            //   let result = {};
            //   const checks = this.columnFilters.check;
            //   const candidates = this.columnFilters.candidate;
            //   let checkViewOgList = JSON.parse(JSON.stringify(this.checkViewList));
            //   let candidateViewOgList = JSON.parse(
            //     JSON.stringify(this.candidateViewList)
            //   );
            //   let checksList, candidateList;
            //   if (checks && Object.keys(checks).length) {
            //     checksList = this.checkViewList.length
            //       ? checkViewOgList.filter((chk) => {
            //           return Object.keys(checks).some((fil) => {
            //             return checks[fil].includes(chk[fil]);
            //           });
            //         })
            //       : [];
            //     result.checksList = checksList;
            //   } else {
            //     result.checksList = this.checkViewList;
            //   }
            //   if (candidates && Object.keys(candidates).length) {
            //     candidateList = this.checkViewList.length
            //       ? candidateViewOgList.filter((chk) => {
            //           return Object.keys(candidates).some((fil) => {
            //             return candidates[fil].includes(chk[fil]);
            //           });
            //         })
            //       : [];
            //     result.candidateList = candidateList;
            //   } else {
            //     result.candidateList = this.candidateViewList;
            //   }
            return {
                candidateList: this.candidateViewList,
                checksList: this.checkViewList,
                taskViewList: this.taskViewList,
            };
        },
        columnFilterOptions() {
            return {
                check: this.getFilterOptions(this.cardData.tableLarge.checkView.columns, this.checkViewList),
                candidate: this.getFilterOptions(this.cardData.tableLarge.candidateView.columns, this.candidateViewList),
                task: this.getFilterOptions(this.cardData.tableLarge.taskView.columns, this.taskViewList, "task"),
            };
        },
        getPaginationOptions() {
            const selected = this.fakeData.tabs.list.find((tab) => tab.id === this.fakeData.tabs.selected);
            return { ...this.paginationOptions, perPage: this.serverParams[selected.key].perPage };
        },
    },
    methods: {
        ...mapMutations(["SET_CASE_DETAILS"]),
        selectCard(card) {
            this.selectedCard = card;
            this.getCaseStatusDetails(card.id);
        },
        async selectTab(id) {
            this.fakeData.tabs.selected = id;
            switch (id) {
                case 1:
                    await this.getCandidateViews();
                    break;
                case 2:
                    await this.getCheckLists();
                    break;
                case 3:
                    await this.getTaskView();
                    break;

                default:
                    break;
            }
        },

        /**
         * params: query params for api
         * description: to fetch all case list for case list table
         */
        async getCandidateViews(params = null) {
            this.isLoading = true;
            let qParams = new URLSearchParams();
            let filterParams = {};

            const getFilterValue = (key) => {
                if (["form_status", "case_status", "teams", "users"].includes(key)) {
                    return this.columnFilters.candidate[key]["id"];
                } else return this.columnFilters.candidate[key]["name"];
            };
            if (this.columnFilters.candidate)
                Object.keys(this.columnFilters.candidate).map((key) => {
                    // if that key exist
                    // add it to filter else we'll delete it
                    // so it wont make empty strings in parameters
                    filterParams[key] = this.columnFilters.candidate[key] ? getFilterValue(key) : delete filterParams[key];
                });
            if (params) {
                Object.keys(params).forEach((key) => {
                    // if (Array.isArray(params[key])) {
                    params[key].map((ele) => {
                        qParams.append(key, ele);
                    });
                    // } else qParams.set(key, params[key])
                });
            }
            let { data, error } = await axios(`/dashboard?${qParams}`, {
                params: {
                    ...filterParams,
                    page: this.serverParams.candidate.page,
                    count: this.serverParams.candidate.perPage,
                },
            });
            if (error) {
                console.log(error, "getCandidateViews >>>");
            } else {
                this.candidateViewList = data.data;
                this.candidatesTotalPage = data.page;
            }
            this.isLoading = false;
        },

        /**
         * params: query params for api
         * description: to fetch all check list for check list table
         */
        async getCheckLists(params = null) {
            this.isLoadingChecks = true;
            let filterParams = {};
            let qParams = new URLSearchParams();
            const getFilterValue = (key) => {
                if (["form_status", "case_status"].includes(key)) {
                    return this.columnFilters.check[key]["id"];
                } else return this.columnFilters.check[key]["name"];
            };
            if (params) {
                Object.keys(params).forEach((key) => {
                    // if (Array.isArray(params[key])) {
                    params[key].map((ele) => {
                        qParams.append(key, ele);
                    });
                    // } else qParams.set(key, params[key])
                });
            }
            if (this.columnFilters.check)
                Object.keys(this.columnFilters.check).map((key) => {
                    // if that key exist
                    // add it to filter else we'll delete it
                    // so it wont make empty strings in parameters
                    filterParams[key] = this.columnFilters.check[key] ? getFilterValue(key) : delete filterParams[key];
                });
            try {
                const { data } = await axios(`/dashboard/checks?${qParams}`, {
                    params: {
                        ...filterParams,
                        page: this.serverParams.check.page,
                        count: this.serverParams.check.perPage,
                    },
                });
                // format date into YYYY-MM-DD
                const formateDate = (date) => DateTime.fromISO(date).toFormat("yyyy-LL-dd");
                this.checkViewList = data.data.map((el) => ({
                    ...el,
                    created_at: formateDate(el.created_at),
                    due_date: formateDate(el.due_date),
                }));
                this.checksTotalPages = data.page;
            } catch (error) {
                console.log("error.message :>> ", error.message);
            }
            this.isLoadingChecks = false;
        },

        async getTaskView() {
            const { task } = this.columnFilters;
            console.log("task :>> ", task);
            this.loadingStates.taskView = true;
            try {
                let payload = {
                    activity_type: task?.activity_type?.value || null,
                    assignee_role: task?.assignee_role?.value || null,
                    assignee: task?.assignee?.value || null,
                    acknowledged: task?.status ? task.status.value : null,
                    candidate_first_name: task?.candidate_first_name?.value || null,
                    candidate_last_name: task?.candidate_last_name?.value || null,
                    visible_case_id: task?.visible_case_id?.value || null,
                    check: task?.check?.value || null,
                    page: this.serverParams.task.page,
                    count: this.serverParams.task.perPage,
                };
                const { data } = await axios.post(`dashboard-notifications/tenant`, payload);
                this.taskViewList = data?.data?.data;
                const formateDate = (date) => (date ? DateTime.fromISO(date).toFormat("yyyy-LL-dd") : "");
                this.taskViewList = this.taskViewList.map((ele) => ({ ...ele, due_date: { value: formateDate(ele.due_date.value), label: formateDate(ele.due_date.label) } }));
                this.tasksTotalPages = { total: data?.data?.page?.total_count ?? 0, total_pages: data?.data?.page?.total_pages ?? 0 };
            } catch (error) {
                console.log(">>>> error", error);
            }
            this.loadingStates.taskView = false;
        },
        // handle link type cell => click
        // async handleCaseClick(caseId) {
        // },

        async getCaseStatusCount() {
            try {
                const { data } = await axios(`dashboard/case-status-count`);
                this.caseCount = data?.data;
            } catch (error) {
                console.log("error :>> ", error);
            }
        },

        async fetchAllStatusOptions() {
            try {
                const response = await axios(`case/status`);
                this.allStatusOptions = response.data.data?.case_status;
            } catch (error) {
                console.log("error :>> ", error);
            }
        },
        async fetchAllFormStatus() {
            try {
                const response = await axios(`case/form-status`);
                this.allFormStatusOptions = response.data.data;
            } catch (error) {
                console.log("error :>> ", error);
            }
        },

        async handleAutomatedCase(data) {
            if (data.automated) {    
            let url = `/integrations/neotas/${data.id}`
            const result = await axios.get(url)
            this.$router.push({
                name: "results-details",
                params: {tool: "political-exposure", neotasCase: result.data.immediate_resp_success_json.case_id},
                query: {delviumCase: result.data.case_id}
            })
            }
            else {
                this.$router.push( { 
                    name: 'Profile Container',
                    params: { caseid: data.case_id },
                    query: { caseid: data.id || data?.case_uuid },         
                })
            }
        },
        async handleCheckClicked(case_data) {
            let query = { caseid: case_data.case_uuid, check: case_data.tenant_check_id, type: case_data && case_data.case_reference_id ? "Reference" : "Non Reference" };
            // if (case_data) {
            // query.type =
            // }
            let link = await this.$router.push({
                name: "Profile Container",
                params: { caseid: case_data.case_id },
                query,
            });
            return link;
        },
        async handleStatusClick(row_data) {
            const case_id = row_data.row.id;
            this.statusModalTitle = row_data.row.case_id.toUpperCase() + "        " /* + row_data.row.screening_package_name.toUpperCase() + */ + " Status";
            // let fields = ['candidate_first_name', ]

            const today = new Date();
            const lists = ["candidate_first_name", "candidate_last_name", "candidate_email", "candidate_phone"];
            const calculateDays = (today, date) => (today.getTime() - new Date(date).getTime()) / (1000 * 3600 * 24);
            Object.keys(this.statusModalTable.row).map((el) => {
                if (lists.includes(el)) {
                    this.statusModalTable.row[el] = row_data.row[el] || "-";
                } else if (el === "days_remaining" && row_data.row?.created_at) {
                    this.statusModalTable.row[el] = Math.ceil(calculateDays(today, row_data.row.created_at)) + "  Days";
                } else if (el === "days_so_far" && row_data.row?.due_date) {
                    this.statusModalTable.row[el] = Math.ceil(calculateDays(today, row_data.row?.due_date)) + "  Days";
                } else {
                    this.statusModalTable.row[el] = "-";
                }
            });
            try {
                const { data } = await axios(`case/${case_id}/status-timeline`);
                if (data)
                    this.timelineData.timeline = data.data.map((el) => ({
                        date: new Date(el.timestamp),
                        title: el.status_name,
                        description: "",
                        color: el.status_color,
                    }));

                const { data: timeline } = await axios(`case/${case_id}/checks-timeline`);
                this.checksTimeline = timeline.data.map((obj) => {
                    let data = {};
                    data = {
                        header: {
                            label: obj.check_name,
                            icon: "#",
                        },
                    };
                    data.timeline = obj.timeline.map((el) => ({
                        date: new Date(el.from_date),
                        title: el.title,
                        description: el.status_name,
                        color: el.status_color,
                    }));
                    return data;
                });
                this.$refs["status-modal"].showModal();
            } catch (error) {
                console.log("error :>> ", error);
            }
        },

        handleCloseStatusModal() {
            this.statusModalTitle = " ";
            this.$refs["status-modal"].hideModal();
        },
        async handleCaseClick(caseData) {
            console.log("handle case click")
            // TODO check if doesn't duplicate functionality from case data fetching
            if (caseData && caseData.case_id) {
                this.SET_CASE_DETAILS(caseData);
                this.$router.push({
                    name: "Profile Container",
                    params: { caseid: caseData.case_id },
                    query: { caseid: caseData.id },
                });
            }
        },
        formatFn: (value) => {
            if (value) value = DateTime.fromISO(value).toFormat("EEEE, dd LLLL yyyy");
            else value = "-";
            return value;
        },

        async onPageChange(data, type, resetPage = false) {
            console.log("data,type :>> ", data, type);
            if (resetPage) {
                this.serverParams[type].page = 1;
            }
            if (type) {
                if (!resetPage) this.serverParams[type].page = data.currentPage;
                this.serverParams[type].perPage = data.currentPerPage;
                try {
                    // type === "check" ? this.getCheckLists(this.candidateViewParams) : this.getCandidateViews(this.candidateViewParams);
                    const conf = {
                        candidate: this.getCandidateViews,
                        check: this.getCheckLists,
                        task: this.getTaskView,
                    };
                    await conf[type](this.candidateViewParams);
                } catch (error) {
                    console.log("error fetching table query :>> ", error);
                }
            }
        },
        async onColumnFilters({ columnFilters }, type) {
            // let columnFilters = filter.id
            // Object.keys(columnFilters).forEach((key) => {
            //     if (!columnFilters[key]?.length > 0) delete columnFilters[key];
            // });
            this.columnFilters[type] = columnFilters ? { ...columnFilters } : null;
            this.serverParams[type].page = 1;

            const conf = {
                candidate: this.getCandidateViews,
                check: this.getCheckLists,
                task: this.getTaskView,
            };
            await conf[type]();
        },
        async clearFilters(type) {
            this.columnFilters[type] = null;
            const conf = {
                candidate: this.getCandidateViews,
                check: this.getCheckLists,
                task: this.getTaskView,
            };
            await conf[type]();
        },
        getFilterOptions(columns, rows, type) {
            if (type === "task") {
                return columns.reduce((result, curr) => {
                    const fields = curr.field.split(".");
                    result[curr.field] = rows
                        .filter((row) => {
                            return fields?.length === 1 ? row[curr.field] : row[fields[0]][fields[1]];
                        })
                        .map((row) => {
                            let obj = { id: uuid.v4(), name: fields?.length === 1 ? row[curr.field] : row[fields[0]][fields[1]], value: row[fields[0]]?.value };
                            if (!obj.value) {
                                obj.value = obj.name;
                            }
                            return obj;
                        });
                    result[curr.field] = uniqBy(result[curr.field], "name");
                    return result;
                }, {});
            }
            return columns.reduce((result, curr) => {
                result[curr.field] = rows
                    .filter((row) => row[curr.field])
                    .map((row) => row[curr.field])
                    .filter(onlyUnique)
                    .map((el) => ({ id: uuid.v4(), name: el }));
                return result;
            }, {});
        },
        async getCaseStatus() {
            this.caseCountLoader = true;
            try {
                const { data } = await axios(`dashboard/events`);
                this.caseStatusCard = data?.data.map((el) => ({
                    title: el.name,
                    color: el.color,
                    id: el.id,
                    value: el.value,
                }));
                //  :title="card.label"
                // :value="card.value"
                // :color="card.color"
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.caseCountLoader = false;
        },

        async getCaseStatusDetails(event_id) {
            try {
                const { data } = await axios(`dashboard/events/${event_id}`);
                this.caseEventDetails = data.data;
                this.caseEventDetails.data = this.caseEventDetails.data.map((ele) => ({
                    ...ele,
                    label: ele.name,
                    selected: false,
                }));
            } catch (error) {
                console.log("error :>> ", error);
            }
        },
        // delay for searching:
        getSearchOptionsTick(payload, type) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload, type);
            }, delay);
        },

        async getSearchOptions(payload, type) {
            console.log("payload, type :>> ", payload, type);
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.$set(this.columnFilterOptionsAsync, type, null);
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading[type], payload.column.field, true);

                try {
                    let newFilterVal = null;
                    if (type === "candidate" || type === "check") {
                        const requestData = {
                            ...this.columnFilters[type],
                            [payload.column.query_key]: payload.value,
                        };
                        const url = type === "candidate" ? "dashboard" : "/dashboard/checks";
                        const { data } = await axios.get(url, {
                            params: requestData,
                        });
                        newFilterVal = data.data;
                    } else if (type === "task") {
                        console.log("this.columnFilters[type] :>> ", this.columnFilters[type]);
                        let filters = {};
                        if (this.columnFilters[type]) {
                            //fetching out the value from the object
                            Object.entries(this.columnFilters[type]).forEach(([key, value]) => {
                                let obj = {};
                                obj[key] = value.value;
                                filters = { ...filters, ...obj };
                            });
                        }
                        const requestData = {
                            ...filters,
                            [payload.column.query_key]: payload.value,
                        };
                        const { data } = await axios.post(`dashboard-notifications/tenant`, requestData);
                        newFilterVal = data?.data?.data ?? [];
                    }
                    // set async options:
                    const columns = {
                        check: this.cardData.tableLarge.checkView.columns,
                        candidate: this.cardData.tableLarge.candidateView.columns,
                        task: this.cardData.tableLarge.taskView.columns,
                    };
                    this.$set(this.columnFilterOptionsAsync, type, this.getFilterOptions(columns[type], newFilterVal, type));
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading[type], payload.column.field, false);
            }
        },
        async handleRowClick({ id, value }) {
        console.log("gggggggggg")
            this.candidateViewParams = {};
            this.caseEventDetails.data = this.caseEventDetails.data.map((el) => (el.id === id ? { ...el, selected: value } : el));
            // row is checked
            const key = "events";
            this.caseEventDetails.data.forEach((card) => {
                if (card.selected) {
                    // if (this.candidateViewParams) {
                    if (this.candidateViewParams[key]) this.candidateViewParams[key] = [...this.candidateViewParams[key], card["id"]];
                    else this.candidateViewParams[key] = [card["id"]];
                    // });
                }
            });
            // // row is unchecked
            // this.getCandidateViews(this.candidateViewParams);
            const conf = {
                candidate: this.getCandidateViews,
                check: this.getCheckLists,
            };
            Object.keys(conf).forEach(async (type) => {
                await conf[type](this.candidateViewParams);
            });
        },
        async onWorkAllocation(rows) {
            this.usersStates = [];
            this.teamsStates = [];
            // this.fetchAllTeams();
            // this.fetchAllUsers();
            this.fetchAllocatedStates("users", rows);
            this.fetchAllocatedStates("teams", rows);
            this.$modal.show("work-allocation", { rows });
        },
        async fetchAllocatedStates(type, rows) {
            let url = "";
            const payload = {
                case_ids: rows.map((row) => ({ id: row.id })),
            };
            this.loadingStates[type] = true;
            if (type === "users") {
                url = "/work-allocation/case/user-state";
            } else {
                this.loadingStates.teams = true;
                url = "/work-allocation/case/group-state";
            }
            try {
                const { data } = await axios.post(url, payload);
                this[`${type}States`] = data?.map((res) => ({ ...res, status: null }));
            } catch (error) {
                console.log(error, "[+] error while fetching teams");
            }
            this.loadingStates[type] = false;
        },
        onCaseDelete(rows) {
            console.log("rows :>> ", rows);
        },
        async fetchAllTeams() {
            this.loadingStates.teams = true;
            try {
                const { data } = await axios.get(`/group/tenant/${this.getTenantId}`);
                this.allTeams = data.map((team) => ({ ...team, status: null }));
            } catch (error) {
                console.log(error, "[+] error while fetching teams");
            }
            this.loadingStates.teams = false;
        },
        async fetchAllUsers() {
            this.loadingStates.users = true;
            try {
                const { data } = await axios.get(`/user/tenant/${this.getTenantId}`);
                this.allUsers = data.map((user) => ({ ...user, status: null }));
            } catch (error) {
                console.log(error, "[+] error while fetching users");
            }
            this.loadingStates.users = false;
        },
        onWorkAllocateChange({ index, value, type }) {
            this[type][index].status = this[type][index].status === value ? null : value;
        },
        async onWorkAllocationSave({ cases, type }) {
            //type: team or user
            let url = "";
            let payload = {
                case_ids: cases.map((cas) => ({ id: cas.id })),
            };
            console.log(this.teamsStates, "asdasd");
            if (type === "team") {
                url = "/work-allocation/case/tenant-group";
                payload.add_groups = this.teamsStates.filter((user) => user.status === "add").map((elem) => elem.id);
                payload.remove_groups = this.teamsStates.filter((user) => user.status === "remove").map((elem) => elem.id);
            } else {
                url = "/work-allocation/case/tenant-user";
                payload.add_users = this.usersStates.filter((user) => user.status === "add").map((elem) => elem.id);
                payload.remove_users = this.usersStates.filter((user) => user.status === "remove").map((elem) => elem.id);
            }
            try {
                await axios.post(url, payload);
                this.$toast.success("Saved Successfully!");
            } catch (error) {
                this.$toast.error("Saving Failed!");
            }
        },
        async fetchAllocationList(caseId, type) {
            this.loadingStates.getAllocation = true;
            const url = `/work-allocation/case/${caseId}/${type}`;
            try {
                const { data } = await axios.get(url);
                this.allocatedResources = data?.filter((res) => res.allotted) ?? [];
            } catch (error) {
                console.log(error, "[+] error while fetch allocation list");
            }
            this.loadingStates.getAllocation = false;
        },
        async onAllocation({ row, type }) {
            this.allocatedResources = [];
            this.$modal.show("allocation-list", { type, row });
            await this.fetchAllocationList(row.id, type);
        },
        async removeAllocation({ resource, type, row }) {
            this.loadingStates.getAllocation = true;
            let url = "";
            let payload = {
                case_ids: [{ id: row.id }],
            };
            if (type === "groups") {
                url = "/work-allocation/case/tenant-group";
                payload.add_groups = [];
                payload.remove_groups = [resource.id];
            } else {
                url = "/work-allocation/case/tenant-user";
                payload.add_users = [];
                payload.remove_users = [resource.id];
            }
            try {
                await axios.post(url, payload);
                await this.fetchAllocationList(row.id, type);
                this.getCandidateViews();
                this.$toast.success("Removed Successfully!");
            } catch (error) {
                this.$toast.error("Removing Failed!");
            }
            this.loadingStates.getAllocation = false;
        },
        async onAllocationReset({ type, rows }) {
            await this.fetchAllocatedStates(type, rows);
        },
        removeHTML(str) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = str;
            return tmp.textContent || tmp.innerText || "";
        },
        checkData(value) {
            let item = value.customData;
            if (item?.activity_type?.value === "case_task" || item?.activity_type?.value === "email") {
                return this.removeHTML(item.title.label);
            } else {
                return item.title.label;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/mixins/mixins.scss";
::v-deep {
    .button-base {
        @include buttonBase(#fff, #029adb, #029adb);
    }
}

.page1 {
    gap: 0;
    .card-panel {
        padding-bottom: 15px;
        .card {
            &--selected {
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    bottom: -15px;
                    width: 0;
                    height: 0;
                    border-left: 26px solid transparent;
                    border-right: 26px solid transparent;
                    border-bottom: 28px solid #fff;
                    box-sizing: border-box;
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
        }
    }
    .content {
        background-color: #fff;
        // padding: 40px;
        border-radius: 24px;
        .chart {
            height: 375px;
        }
        .chart,
        .table {
            flex: 1 0 40%;
        }
    }
}
</style>
