
import boldBiConfig from "./config";
import axios from 'axios';
import VueCookies from "vue-cookies";
import {getBaseUrlDelviumBackend} from "@shared/utils/functions"

const bBHelper = {

    httpRequest: (url, params, method, cb, authToken, isJSON, isInternal)=>{
         
        const headers = {};
        
        if(isJSON){
            headers["Content-Type"] = "application/json";
        }
        if (authToken) {
          headers["Authorization"] = `Bearer ${authToken}`;
        }
        if (isInternal) {
            let token = VueCookies.get("token");
            headers["Authorization"] = `Bearer ${token}`;
          }



        const httpConfig = {
            method: method,
            url: url, 
            headers: headers
        }
 
        if(method==='get'){
            httpConfig['params'] = params;
        }
        else {
            if(Object.keys(params).length>0){
                httpConfig['data'] = JSON.stringify(params)
            }
            
        }
        //console.log("httpconfig",httpConfig)
        axios(httpConfig)
          .then(function (response) {
            // On Success
            if(response.status >= 200 && response.status < 400) {
                //console.log("Response", response?.data);
                cb(false, response?.data, authToken);
            } 
            
          })
          .catch(function (error) {
            // On Failure
            //console.log("error 123", error.response.data)

            cb(error?.response?.data);
          });

    },

    getAuthToken: async (cb, isHardRefresh)=>{
        let localTokenData = localStorage.getItem("BOLDBI_TOKEN_DATA");
        const currentTimeStamp = Math.floor(Date.now() / 1000);
        if(localTokenData && !isHardRefresh){
            localTokenData= JSON.parse(localTokenData)
            const tokenExpiryTimeStamp= Date.parse(localTokenData['.expires']) / 1000;
            
        if (currentTimeStamp < tokenExpiryTimeStamp) {
            
            return cb(false, localTokenData.access_token)
            }
        }

        let url = getBaseUrlDelviumBackend()+'/dashboard/get-token';
        bBHelper.httpRequest(url, {}, 'post', (err, response)=>{
            if(err){
                return cb(err)
            }
            
            cb(false, response?.token?.access_token)
              
            localStorage.setItem('BOLDBI_TOKEN_DATA', JSON.stringify(response.token));
            
        }, false, true, true)  
    },

    generateEmbedUrl: ()=>{
        
        return  getBaseUrlDelviumBackend() + boldBiConfig.EMBED_ENDPOINT ;
    },
    getDashboardList: (page, page_size)=>{
        const isFirstPageLoad= (page==1)
        var params={
            page:page,
            page_size: page_size,
        }
        
        return new Promise((resolve, reject)=>{
            bBHelper.getAuthToken((err, token)=>{
                let url = boldBiConfig.BASE_URL + boldBiConfig.DASHBOARD_LIST_ENDPOINT;
                
                if(err){
                    reject(err);
                }
                bBHelper.httpRequest(
                    url,
                    params,
                    'get',
                    (err, data)=>{
    
                        if(err){
                            reject(err);
                        }
                        return resolve(data)
                    },
                    token
                );
            }, isFirstPageLoad)           

        })
 
    },
    getDashboardData:(dashboardId)=>{
        
        
        return new Promise((resolve, reject)=>{
            bBHelper.getAuthToken((err, token)=>{
                let url = boldBiConfig.BASE_URL + boldBiConfig.DASHBOARD_DATA_ENDPOINT+dashboardId;
                
                if(err){
                    reject(err);
                }
                bBHelper.httpRequest(
                    url,
                    {},
                    'get',
                    (err, data)=>{
    
                        if(err){
                            reject(err);
                        }
                        return resolve(data)
                    },
                    token
                );
            })           

        })
    },
    deleteDashboard: (dashboardId)=>{

    return new Promise((resolve, reject)=>{
        bBHelper.getAuthToken( (err, token)=>{
            let url = boldBiConfig.BASE_URL + boldBiConfig.DASHBOARD_DELETE_ENDPOINT + dashboardId;
            if(err){
                reject(err);
            }
            bBHelper.httpRequest(
                url,
                {},
                'delete',
                (err, data)=>{

                    if(err){
                        reject(err);
                    }
                    return resolve(data)
                },
                token
            );
        })           

    })
    },
    markDashboardFavorite: (dashboardId, favorite)=>{
        var params = {
              DashboardId: dashboardId,
              Favorite: favorite,
          };
        
        return new Promise((resolve, reject)=>{
            bBHelper.getAuthToken((err, token)=>{
                let url = boldBiConfig.BASE_URL + boldBiConfig.DASHBOARD_UPDATE_FAVORITE_ENDPOINT;
                
                if(err){
                    reject(err);
                }
                bBHelper.httpRequest(
                    url,
                    params,
                    'put',
                    (err, data)=>{
                        if(err){
                            reject(err);
                        }
                        return resolve(data)
                    },
                    token,
                    true
                );
            })           

        })
 
    },
    dashboardFavoriteList: async()=>{
  
        return new Promise((resolve, reject)=>{
            bBHelper.getAuthToken((err, token)=>{
                let url = boldBiConfig.BASE_URL + boldBiConfig.DASHBOARD_UPDATE_FAVORITE_ENDPOINT;
               
                if(err){
                    reject(err);
                }
                bBHelper.httpRequest(
                    url,
                    {},
                    'get',
                    (err, data=[])=>{
                        
                        if(err){
                            reject(err);
                        }
                    
                        return resolve(data)
                    },
                    token,
                
                );
            })           

        })
 
    },
    createDraftDashboard:( name)=>{
        var params = {
            name: name,
          };
        
        return new Promise((resolve, reject)=>{
            bBHelper.getAuthToken((err, token)=>{
                let url = boldBiConfig.BASE_URL + boldBiConfig.CREATE_DRAFT_DASHBOARD+ "?name="+ name; 
               
                if(err){
                    return reject(err);
                }

                bBHelper.httpRequest(
                    url,
                    params,
                    'post',
                    (err, data)=>{
                        if(err){
                            reject(err);
                        }
                        return resolve(data)
                    },
                    token,
                    true
                );
            })           

        })
 
    },
    draftDashboardList:()=>{
        
        return new Promise((resolve, reject)=>{
            bBHelper.getAuthToken((err, token)=>{
                let url = boldBiConfig.BASE_URL + boldBiConfig.CREATE_DRAFT_DASHBOARD; 
              
                if(err){
                    reject(err);
                }
                bBHelper.httpRequest(
                    url,
                    {},
                    'get',
                    (err, data)=>{
    
                        if(err){
                            reject(err);
                        }
                        return resolve(data)
                    },
                    token
                );
            })           

        })
 
    },
    biLogout:(data)=>{
        if(data){
            return localStorage.removeItem('token');
         }
        
    },
    getBiLocalStorageData:()=>{
        return localStorage.getItem("BOLDBI_TOKEN_DATA");
    },

    


}

export default bBHelper;

