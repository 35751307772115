import AppLogo from "@/assets/images/delvium-logo.png";
import delviumLogo from "@/assets/images/logo.png";
import  LoginImg  from "@/assets/images/loginScreen.png" ;
import  Logo1 from "@/assets/images/neotasLogo.png" ;
// import bgImg from "@/assets/svg/auth-bg.svg"

const logoImg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 24.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 148.1 47.9" style="enable-background:new 0 0 148.1 47.9;" xml:space="preserve">
<style type="text/css">
	.st0{opacity:0.75;}
	.st1{fill:#FFFFFF;}
	.st2{opacity:0.15;fill:#8F9AAA;}
	.st3{opacity:0.75;fill:#8F9AAA;}
	.st4{opacity:0.75;fill:#8F9AAA;stroke:#8F9AAA;stroke-width:0.25;stroke-miterlimit:10;}
	.st5{fill:#FDFCFA;}
	.st6{fill:#4EB7A3;}
	.st7{fill:#5FA33B;}
	.st8{fill:#9F4E21;}
	.st9{fill:#0D69D5;}
	.st10{fill:#5745D8;}
	.st11{fill:#E54E56;}
	.st12{fill:#2B3449;}
	.st13{fill:#CD820D;}
	.st14{fill:#A4C457;}
	.st15{fill:#D6BE47;}
	.st16{fill:#0078BD;}
	.st17{fill:#435897;}
</style>
<g>
	<path class="st1" d="M69,17.4h-8.2c-2,0-3.6,1.6-3.6,3.6v10.5h2.5V21c0-0.6,0.5-1.1,1.1-1.1H69c0.6,0,1.1,0.5,1.1,1.1v10.5h2.5V21
		C72.6,19,71,17.4,69,17.4z"/>
	<path class="st1" d="M100.2,17.4h-7c-2,0-3.6,1.6-3.6,3.6v7c0,2,1.6,3.6,3.6,3.6h7c2,0,3.6-1.6,3.6-3.6v-7
		C103.8,19,102.2,17.4,100.2,17.4z M101.3,28c0,0.6-0.5,1.1-1.1,1.1h-7c-0.6,0-1.1-0.5-1.1-1.1v-7c0-0.6,0.5-1.1,1.1-1.1h7
		c0.6,0,1.1,0.5,1.1,1.1V28z"/>
	<path class="st1" d="M74.8,21v7c0,2,1.6,3.6,3.6,3.6h9.3v-2.5h-9.3c-0.6,0-1.1-0.5-1.1-1.1v-2.3h6.9v-2.5h-6.9v-2.3
		c0-0.6,0.5-1.1,1.1-1.1h9.3v-2.5h-9.3C76.4,17.4,74.8,19,74.8,21z"/>
	<path class="st1" d="M144.5,23.3h-5.8c-0.6,0-1.1-0.5-1.1-1.1V21c0-0.6,0.5-1.1,1.1-1.1h8.5v-2.5h-8.5c-2,0-3.6,1.6-3.6,3.6v1.2
		c0,2,1.6,3.6,3.6,3.6h5.8c0.6,0,1.1,0.5,1.1,1.1v1.2c0,0.6-0.5,1.1-1.1,1.1h-8.7v2.5h8.8c2,0,3.5-1.6,3.5-3.6v-1.2
		C148.1,24.8,146.5,23.3,144.5,23.3z"/>
	<polygon class="st1" points="118.8,17.4 104.8,17.4 104.8,19.9 110.6,19.9 110.6,31.5 113.1,31.5 113.1,19.9 118.8,19.9 	"/>
	<path class="st1" d="M127.5,17.4h-3.2c-1.6,0-3,1.1-3.4,2.7l-3.2,11.5h2.6l1-3.9h9.2l1,3.9h2.6L130.9,20
		C130.5,18.5,129.1,17.4,127.5,17.4z M122,25.2l1.2-4.5c0.2-0.5,0.6-0.8,1-0.8h3.2c0.5,0,0.9,0.3,1,0.8l1.2,4.5H122z"/>
	<path class="st1" d="M33.4,33.9h2.2c1,0,1.8-0.8,1.8-1.8v-2.3c0-1-0.8-1.8-1.8-1.8h-2.2c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8v-1.6
		c0-1-0.8-1.8-1.8-1.8h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8v-1.6c0-1-0.8-1.8-1.8-1.8h-2.2c-1,0-1.8,0.8-1.8,1.8V18
		c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V25c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V32
		C31.6,33.1,32.3,33.9,33.4,33.9z"/>
	<path class="st1" d="M14.5,42.1h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8v-1.6v-0.3c0-0.9-0.8-1.7-1.7-1.8v-7.9c1-0.1,1.7-0.9,1.7-1.8
		v-2.2c0-1-0.7-1.8-1.7-1.9v-8.2C5,12.7,5.7,12,5.7,11V9.4l5.7-3.8c0.3,0.1,0.5,0.2,0.8,0.2h2.3c0.3,0,0.5-0.1,0.8-0.2L21,9.4V11
		c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V18c0,1,0.8,1.8,1.8,1.8h2.2c1,0,1.8-0.8,1.8-1.8v-2.2c0-1-0.8-1.8-1.8-1.8
		h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8V8.8C26.8,7.8,26,7,25,7h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.6-3.8V1.8c0-1-0.8-1.8-1.8-1.8h-2.3
		c-1,0-1.8,0.8-1.8,1.8v1.6L4.8,7.2C4.6,7.1,4.4,7,4,7H1.8C0.8,7,0,7.8,0,8.8V11c0,0.9,0.8,1.7,1.7,1.8V21C0.8,21.1,0,21.9,0,22.8
		V25c0,0.9,0.8,1.7,1.7,1.8v7.9C0.8,34.8,0,35.6,0,36.5v0.3v2v0.3c0,1,0.8,1.8,1.8,1.8H4c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8v1.6
		c0,1,0.8,1.8,1.8,1.8h2.2c1,0,1.8-0.8,1.8-1.8V44C16.3,42.9,15.6,42.1,14.5,42.1z"/>
	<path class="st1" d="M46.1,7h-2.2c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8V1.8c0-1-0.8-1.8-1.8-1.8h-2.2c-1,0-1.8,0.8-1.8,1.8v2.3
		c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8v1.6c0,0.9,0.8,1.7,1.7,1.8v8.2c-0.9,0.1-1.7,0.9-1.7,1.8v2.2
		c0,0.9,0.8,1.7,1.7,1.8V35c-0.9,0.1-1.7,0.9-1.7,1.8v1.6l-5.7,3.8C36.2,42.1,36,42,35.7,42h-2.3c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8
		v-1.6c0-1-0.8-1.8-1.8-1.8h-2.2c-0.3,0-0.5,0.1-0.8,0.2l-5.7-3.8v-1.6c0-1-0.8-1.8-1.8-1.8h-2.3c-1,0-1.8,0.8-1.8,1.8V32
		c0,1,0.8,1.8,1.8,1.8h2.3c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V39c0,1,0.8,1.8,1.8,1.8h2.2c0.3,0,0.5-0.1,0.8-0.2l5.7,3.8V46
		c0,1,0.8,1.8,1.8,1.8h2.3c1,0,1.8-0.8,1.8-1.8v-1.6l5.7-3.8c0.3,0.1,0.5,0.2,0.8,0.2h2.2c1,0,1.8-0.8,1.8-1.8v-2.2
		c0-0.9-0.8-1.7-1.7-1.8v-8.2c0.9-0.1,1.7-0.9,1.7-1.8v-2.2c0-0.9-0.8-1.7-1.7-1.8v-8.2C47.2,12.7,48,12,48,11V8.8
		C47.9,7.8,47.1,7,46.1,7z"/>
</g>
</svg>
`
          const  bgImg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 960.5 1082" style="enable-background:new 0 0 960.5 1082;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:none;stroke:#D1DAE0;stroke-width:0.3807;stroke-miterlimit:10;}
	.st2{opacity:0.25;fill:#D1DAE0;}
	.st3{opacity:0.5;fill:#EDF0F4;}
	.st4{opacity:0.75;fill:#8F9AAA;}
	.st5{fill:#F6F8FC;}
	.st6{fill:#0D69D5;}
	.st7{fill:none;stroke:#D1DAE0;stroke-width:0.25;stroke-miterlimit:10;}
	.st8{opacity:0.15;fill:#8F9AAA;}
	.st9{fill:none;stroke:#191919;stroke-miterlimit:10;}
	.st10{opacity:0.15;fill:none;stroke:#1A2137;stroke-width:0.5;stroke-miterlimit:10;}
	.st11{fill:#1A2137;}
	.st12{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st13{opacity:0.75;}
	.st14{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-miterlimit:10;}
	.st15{opacity:0.1;fill:none;stroke:#272830;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;}
	.st16{fill:none;stroke:#0D69D5;stroke-width:3;stroke-miterlimit:10;}
	.st17{opacity:0.75;fill:#8F9AAA;stroke:#8F9AAA;stroke-width:0.25;stroke-miterlimit:10;}
	.st18{opacity:0.85;}
	.st19{fill:#272830;}
	.st20{clip-path:url(#SVGID_2_);fill:#0D69D5;}
	.st21{opacity:0.25;clip-path:url(#SVGID_2_);fill:url(#SVGID_3_);}
	.st22{opacity:0.15;clip-path:url(#SVGID_2_);fill:url(#SVGID_4_);}
	.st23{opacity:0.5;clip-path:url(#SVGID_2_);fill:url(#SVGID_5_);}
	.st24{clip-path:url(#SVGID_2_);fill:url(#SVGID_6_);}
	.st25{opacity:0.75;clip-path:url(#SVGID_2_);fill:url(#SVGID_7_);}
	.st26{clip-path:url(#SVGID_2_);fill:url(#SVGID_8_);}
	.st27{clip-path:url(#SVGID_10_);}
	.st28{opacity:0.15;fill:url(#SVGID_11_);}
	.st29{opacity:0.5;fill:url(#SVGID_12_);}
	.st30{fill:url(#SVGID_13_);}
	.st31{opacity:0.25;fill:url(#SVGID_14_);}
	.st32{opacity:0.75;fill:url(#SVGID_15_);}
	.st33{fill:url(#SVGID_16_);}
	.st34{fill:#FFFFFF;stroke:#BCC4C9;stroke-width:0.25;stroke-miterlimit:10;}
	.st35{clip-path:url(#SVGID_18_);}
	.st36{opacity:0.15;fill:url(#SVGID_19_);}
	.st37{opacity:0.5;fill:url(#SVGID_20_);}
	.st38{fill:url(#SVGID_21_);}
	.st39{opacity:0.25;fill:url(#SVGID_22_);}
	.st40{opacity:0.75;fill:url(#SVGID_23_);}
	.st41{fill:url(#SVGID_24_);}
	.st42{clip-path:url(#SVGID_26_);}
	.st43{opacity:0.15;fill:url(#SVGID_27_);}
	.st44{opacity:0.5;fill:url(#SVGID_28_);}
	.st45{fill:url(#SVGID_29_);}
	.st46{opacity:0.25;fill:url(#SVGID_30_);}
	.st47{opacity:0.75;fill:url(#SVGID_31_);}
	.st48{fill:url(#SVGID_32_);}
	.st49{opacity:0.12;fill:none;stroke:#232323;stroke-width:1.2574;stroke-miterlimit:10;}
	.st50{opacity:0.5;}
	.st51{opacity:0.12;fill:none;stroke:#232323;stroke-width:1.5;stroke-miterlimit:10;}
	.st52{fill:none;stroke:url(#SVGID_33_);stroke-width:1.5;stroke-miterlimit:10;}
	.st53{fill:#BCC4C9;}
	.st54{fill:none;stroke:#FFFFFF;stroke-width:1.5;stroke-miterlimit:10;}
	.st55{fill:none;stroke:url(#SVGID_34_);stroke-width:1.5;stroke-miterlimit:10;}
	.st56{clip-path:url(#SVGID_36_);}
	.st57{opacity:0.15;fill:url(#SVGID_37_);}
	.st58{opacity:0.5;fill:url(#SVGID_38_);}
	.st59{fill:url(#SVGID_39_);}
	.st60{opacity:0.25;fill:url(#SVGID_40_);}
	.st61{opacity:0.75;fill:url(#SVGID_41_);}
	.st62{fill:url(#SVGID_42_);}
	.st63{opacity:0.1;fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st64{opacity:0.75;fill:none;stroke:#1C1C1C;stroke-width:1.5;stroke-miterlimit:10;}
	.st65{fill:none;stroke:#0D69D5;stroke-width:1.25;stroke-miterlimit:10;}
	.st66{fill:none;stroke:#BCC4C9;stroke-width:0.25;stroke-miterlimit:10;}
	.st67{fill:none;stroke:#6E809C;stroke-miterlimit:10;}
	.st68{fill:#FFFFFF;stroke:#A8AAB3;stroke-miterlimit:10;}
	.st69{fill:none;stroke:#272830;stroke-width:0.8972;stroke-miterlimit:10;}
	.st70{fill:#828593;}
	.st71{fill:none;stroke:#272830;stroke-miterlimit:10;stroke-dasharray:10.9828,10.9828;}
	.st72{fill:none;stroke:#0D69D5;stroke-width:1.5;stroke-miterlimit:10;}
	.st73{fill:#0E0E0E;}
	.st74{opacity:0.15;fill:#FFFFFF;}
	.st75{opacity:0.25;fill:#FFFFFF;}
	.st76{opacity:0.15;fill:#E0556D;}
	.st77{fill:#E0556D;}
	.st78{opacity:0.75;fill:none;stroke:#D1DAE0;stroke-width:0.5;stroke-miterlimit:10;}
	.st79{fill:none;stroke:#253238;stroke-width:1.25;stroke-miterlimit:10;}
	.st80{fill:none;stroke:#DCE2E2;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;}
	.st81{fill:none;stroke:#63C57D;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;}
	.st82{fill:#63C57D;}
	.st83{fill:#FEFEFE;}
	.st84{clip-path:url(#SVGID_44_);}
	.st85{fill:none;stroke:#D1DAE0;stroke-width:0.5745;stroke-miterlimit:10;}
	.st86{fill:none;stroke:#D1DAE0;stroke-width:0.2407;stroke-miterlimit:10;}
	.st87{fill:none;stroke:#0D69D5;stroke-width:1.3572;stroke-miterlimit:10;}
	.st88{fill:none;stroke:#B5C2C9;stroke-miterlimit:10;}
	.st89{opacity:0.45;fill:#D1DAE0;}
	.st90{fill:none;stroke:#6B7C8E;stroke-miterlimit:10;}
	.st91{opacity:0.6;fill:#151923;}
	.st92{opacity:0.25;fill:none;stroke:#272830;stroke-width:0.5;stroke-miterlimit:10;}
	.st93{opacity:0.85;fill:none;stroke:#6E809C;stroke-miterlimit:10;}
	.st94{opacity:0.75;fill:none;stroke:#B0BBC1;stroke-miterlimit:10;}
	.st95{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
	.st96{fill:none;stroke:#0D69D5;stroke-miterlimit:10;}
	.st97{fill:#FFFFFF;stroke:#BCC4C9;stroke-width:0.5;stroke-miterlimit:10;}
	.st98{fill:none;stroke:#FFFFFF;stroke-width:1.25;stroke-miterlimit:10;}
	.st99{opacity:0.1;fill:#0D69D5;}
	.st100{opacity:0.15;fill:#0D69D5;}
	.st101{clip-path:url(#SVGID_46_);}
	.st102{clip-path:url(#SVGID_48_);}
	.st103{opacity:0.1;fill:#FFFFFF;}
	.st104{opacity:0.08;fill:#2B424A;}
	.st105{fill:none;stroke:#1A1C1C;stroke-miterlimit:10;}
	.st106{clip-path:url(#SVGID_50_);}
	.st107{clip-path:url(#SVGID_52_);}
	.st108{fill:#FFFFFF;stroke:#A5B1B7;stroke-width:0.5;stroke-miterlimit:10;}
	.st109{fill:#EFAC4D;}
	.st110{opacity:0.5;fill:#828593;}
	.st111{fill:none;stroke:#A5B9C4;stroke-miterlimit:10;}
	.st112{fill:#A5B9C4;}
</style>
<g>
	<defs>
		<rect id="SVGID_25_" width="960.5" height="1082"/>
	</defs>
	<clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_25_"  style="overflow:visible;"/>
	</clipPath>
	<g style="clip-path:url(#SVGID_2_);">
		
			<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="699.2911" y1="396.3061" x2="6.9146" y2="1170.7421" gradientTransform="matrix(0.9998 -0.0188 0.0188 0.9998 -22.0916 56.8868)">
			<stop  offset="0" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#0D69D5;stop-opacity:0"/>
		</linearGradient>
		<path style="opacity:0.15;fill:url(#SVGID_3_);" d="M1122.23,1237.67c0.29-9.74-9.96-24.91-14.1-33.12
			c-14.54-28.83-33.18-55.6-54.18-80.08c-76.65-89.35-184.83-145.51-297.19-176c-83.87-22.76-171.02-32.15-257.77-33.29
			c-92.34-1.21-180.63,11.39-273.29-2.2c-159.52-23.4-306.66-96.68-449.66-167.6c-118.34-58.69-233.45-123.86-345-194.59
			c-59.07-37.46-117.15-76.48-174.19-116.96c-27.95-19.84-55.64-40.06-83.02-60.67l22.48,1196.89c0,0,733.2-381.79,1107.65-200.74
			s371.52,480.34,371.52,480.34l431.13-78.2C1106.61,1771.45,1122.22,1237.84,1122.23,1237.67z"/>
		<g>
			
				<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="731.8087" y1="695.0589" x2="196.7127" y2="990.8148" gradientTransform="matrix(0.9998 -0.0188 0.0188 0.9998 -22.0916 56.8868)">
				<stop  offset="0" style="stop-color:#498EDF"/>
				<stop  offset="1" style="stop-color:#0D69D5"/>
			</linearGradient>
			<path style="opacity:0.5;fill:url(#SVGID_4_);" d="M423.07,760.72l-650.21,508.86c-31.83,24.91-37.44,70.91-12.53,102.74h0
				c24.91,31.83,70.91,37.44,102.74,12.53l650.21-508.86c31.83-24.91,37.44-70.91,12.53-102.74l0,0
				C500.9,741.41,454.9,735.8,423.07,760.72z"/>
			<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-148.2347" y1="1164.8137" x2="446.3487" y2="691.9403">
				<stop  offset="0.1185" style="stop-color:#498EDF"/>
				<stop  offset="1" style="stop-color:#0D69D5"/>
			</linearGradient>
			<path style="fill:url(#SVGID_5_);" d="M310.48,710.62l-650.21,508.86c-31.83,24.91-37.44,70.91-12.53,102.74l0,0
				c24.91,31.83,70.91,37.44,102.74,12.53l650.21-508.86c31.83-24.91,37.44-70.91,12.53-102.74l0,0
				C388.31,691.32,342.31,685.71,310.48,710.62z"/>
		</g>
	</g>
	<g style="clip-path:url(#SVGID_2_);">
		<linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="708.6818" y1="-319.6414" x2="468.0309" y2="480.6298">
			<stop  offset="0" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#0D69D5;stop-opacity:0"/>
		</linearGradient>
		<path style="opacity:0.25;fill:url(#SVGID_6_);" d="M-66.31,109.52c-0.29,6.08,5.93,15.68,8.42,20.85
			c8.74,18.18,20.07,35.12,32.9,50.65c46.83,56.7,113.74,93.05,183.56,113.41c52.12,15.2,106.44,22.09,160.61,23.82
			c57.66,1.84,112.96-5,170.67,4.58c99.36,16.49,190.4,63.98,278.89,109.95c73.23,38.04,144.36,80.1,213.2,125.59
			c36.46,24.09,72.27,49.14,107.43,75.1c17.23,12.72,34.28,25.67,51.14,38.87V-75.48c0,0-462.43,229.86-694.19,112.39
			S219.9-267.47,219.9-267.47l-270.2,43.79C-50.29-223.68-66.31,109.42-66.31,109.52z"/>
		<linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="327.3577" y1="402.8123" x2="676.1103" y2="166.8914">
			<stop  offset="0" style="stop-color:#498EDF"/>
			<stop  offset="1" style="stop-color:#0D69D5"/>
		</linearGradient>
		<path style="opacity:0.75;fill:url(#SVGID_7_);" d="M580.34,344.42l655.8-493.65c32.1-24.17,38.54-69.78,14.37-101.89l0,0
			c-24.17-32.1-69.78-38.54-101.89-14.37l-655.8,493.65c-32.1,24.17-38.54,69.78-14.37,101.89l0,0
			C502.62,362.15,548.24,368.59,580.34,344.42z"/>
		<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="422.147" y1="358.064" x2="723.0316" y2="202.493">
			<stop  offset="0" style="stop-color:#498EDF"/>
			<stop  offset="1" style="stop-color:#0D69D5"/>
		</linearGradient>
		<path style="fill:url(#SVGID_8_);" d="M686.44,412.15l791.47-595.78c38.75-29.17,46.51-84.22,17.35-122.97l0,0
			c-29.17-38.75-84.22-46.51-122.97-17.35L580.82,271.84c-38.75,29.17-46.51,84.22-17.35,122.97l0,0
			C592.64,433.55,647.7,441.32,686.44,412.15z"/>
	</g>
</g>
</svg>`

export { AppLogo, delviumLogo,LoginImg,Logo1,bgImg,logoImg };
